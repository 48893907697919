import { Product } from '@/features/products/entities';
import { SearchedProduct } from '../types';
import { Ref, ref } from 'vue';
import { productsSearchApiClientPlugin } from '..';
import { storagePlugin } from '@/features/core/storage';

interface UseProductSearch {
  searchProducts: (
    searchQuery: string,
    searchCategorySlug: string,
    filteredSkus: string[],
  ) => Promise<Product[] | void>;
  products: Ref<Product[]>;
}

export function useProductSearch(): UseProductSearch {
  const products = ref<Product[]>([]);

  const getSearchedProductsSkus = (products: SearchedProduct[]): string[] => {
    return products.map((item) => item.sku);
  };

  const searchProductSkus = async (
    searchQuery: string,
    searchCategorySlug: string,
  ) => {
    const productsSearchResponse = await productsSearchApiClientPlugin
      .get()
      .searchProducts({
        'page[limit]': 12,
        sort: 'relevance',
        serviceType: 'pickup',
        categorySlug: searchCategorySlug,
        q: searchQuery,
      });

    if (productsSearchResponse) {
      return getSearchedProductsSkus(productsSearchResponse);
    } else {
      return [];
    }
  };

  const searchProducts = async (
    searchQuery: string,
    searchCategorySlug: string,
    filteredSkus: string[],
  ): Promise<Product[] | void> => {
    if (!searchCategorySlug) {
      products.value = [];
      return;
    }
    const productSkus = await searchProductSkus(
      searchQuery,
      searchCategorySlug,
    );
    const filteredProductSkus = productSkus.filter(
      (productSku) => !filteredSkus.includes(productSku),
    );

    const searchedProducts = await storagePlugin.get().getAll(Product, {
      filter: { sku: { anyOf: filteredProductSkus } },
      sortBy: 'pickingOrder',
    });

    products.value = searchedProducts;
    return searchedProducts;
  };

  return { searchProducts, products };
}
